import {
  Helmet,
  Theme,
  PreStyledComponents,
  Link,
  ReadMoreLink,
  Interfaces,
  PdfLink,
  IconPhone,
  IconAt,
  IconClock,
  Banner,
  Box,
  Container,
  Heading,
  Row,
  Section,
  routesObject
} from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import Layout from "../../components/disability/Layout"
import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import LocationModal from "../../components/disability/LocationModal"

const { IconWrapper } = PreStyledComponents

const { disabilityTheme } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    explainingOurEnquiryProcessEasyEnglish: Interfaces.FileProps
  }
}

const bannerBreadcrumbs = [routesObject.disabilityHome]

const SpacedSpan = styled.span`
  display: inline-block;
  min-width: 100px;
`

const FeedbackAndComplaints = () => (
  <div className="mt5">
    <Heading size={3}>Do you have feedback or a complaint?</Heading>
    <p>
      We value your feedback and will work to resolve any issues. You can fill
      out our{" "}
      <Link
        to="/contact-us/complaints-or-feedback/"
        className="black fw4"
        rooted
      >
        online form
      </Link>
      , call our team on{" "}
      <Link
        className="black fw4"
        to="tel:1800935483"
        ariaLabel="Phone number 1 8 0 0. 9 3 5. 4 8 3."
        rooted
      >
        1800 935 483
      </Link>{" "}
      or email us at{" "}
      <Link to="mailto:info@lwb.org.au" className="black fw4">
        info@lwb.org.au
      </Link>
    </p>
    <ReadMoreLink
      href="/contact-us/complaints-or-feedback/"
      rooted
      text="More Information"
      className="mt3"
    />
  </div>
)

const ContactUs = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    explainingOurEnquiryProcessEasyEnglish
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Contact our Disability Services - We're here for you | ${siteMetadata.title}`}
        description="Get in touch to discuss how our Disability Services can support you or someone you care for."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout>
        <Banner
          image={bannerImage}
          title="We're here to help"
          breadcrumbs={bannerBreadcrumbs}
        />
        <Container>
          <Row className="flex-wrap relative">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>
                Contact our disability services enquiries team
              </Heading>
              <p>
                Please get in touch to discuss your plan, ask questions, or
                request more information. We&apos;d love to hear from you.
              </p>
              <Heading size={3} topMargin="standard">
                New service enquiries
              </Heading>
              <div className="pl1 pt3-ns mv3 mb5-l flex flex-column flex-row-l">
                <div className="w-40-l">
                  <div className="flex items-center">
                    <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                      <IconPhone
                        height="20"
                        width="20"
                        color={disabilityTheme.dark}
                      />
                    </IconWrapper>
                    <div className="pl3">
                      <Link
                        className="black fw3 no-underline-ns f3-ns fw8-ns"
                        to="tel:1800610699"
                        ariaLabel="Phone 1 8 0 0. 6 1 0. 6 9 9"
                      >
                        1800 610 699
                      </Link>
                    </div>
                  </div>
                  <div className="mt2 flex items-center">
                    <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                      <IconAt
                        height="20"
                        width="20"
                        color={disabilityTheme.dark}
                      />
                    </IconWrapper>
                    <div className="pl3">
                      <a
                        className="black fw4"
                        href="mailto:disability.enquiries@lwborg.au"
                      >
                        disability.enquiries@lwb.org.au
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex pt4 pv4-ns pv0-l pl5-l">
                  <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                    <IconClock
                      height="20"
                      width="20"
                      color={disabilityTheme.dark}
                    />
                  </IconWrapper>
                  <div className="pl3">
                    <p className="mt0 fw8">Opening hours (AEST)</p>
                    <p className="mt1">
                      <SpacedSpan>Mon - Fri</SpacedSpan>
                      8:30AM - 5PM
                    </p>
                    <p className="mt0">
                      <SpacedSpan>Sat - Sun</SpacedSpan>
                      Closed
                    </p>
                  </div>
                </div>
              </div>
              <Heading size={3} topMargin="standard">
                Existing clients and general disability service enquiries
              </Heading>
              <div className="pl1 pt3-ns mv3 mb5-l flex flex-column flex-row-l">
                <div className="w-40-l">
                  <div className="flex items-center">
                    <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                      <IconPhone
                        height="20"
                        width="20"
                        color={disabilityTheme.dark}
                      />
                    </IconWrapper>
                    <div className="pl3">
                      <Link
                        className="black fw3 no-underline-ns f3-ns fw8-ns"
                        to="tel:1800935483"
                        ariaLabel="Phone 1 8 0 0. 9 3 5. 4 8 3"
                      >
                        1800 935 483
                      </Link>
                    </div>
                  </div>
                  <div className="mt2 flex items-center">
                    <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                      <IconAt
                        height="20"
                        width="20"
                        color={disabilityTheme.dark}
                      />
                    </IconWrapper>
                    <div className="pl3">
                      <a className="black fw4" href="mailto:yourLWB@lwb.org.au">
                        yourLWB@lwb.org.au
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex pt4 pv4-ns pv0-l pl5-l">
                  <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                    <IconClock
                      height="20"
                      width="20"
                      color={disabilityTheme.dark}
                    />
                  </IconWrapper>
                  <div className="pl3">
                    <p className="mt0 fw8">Opening hours (AEST)</p>
                    <p className="mt1">
                      <SpacedSpan>Mon - Fri</SpacedSpan>
                      8:30AM - 5PM
                    </p>
                    <p className="mt0">
                      <SpacedSpan>Sat - Sun</SpacedSpan>
                      Closed
                    </p>
                  </div>
                </div>
              </div>
              <Heading size={3} topMargin="standard">
                Other ways to get in touch
              </Heading>
              <div className="mt3 copy">
                <p className="pb2">
                  If you have hearing or speech impairment National Relay
                  Service can help you with your call.
                </p>
              </div>
              <div className="mt3 copy">
                <div className="pb2">
                  <div>
                    <span className="fw4 pr1">Voice Relay</span>
                    <Link
                      className="black fw8 link"
                      to="tel:1300555727"
                      ariaLabel="Phone number 1 3 0 0. 5 5 5. 7 2 7"
                      rooted
                    >
                      1300 555 727
                    </Link>
                  </div>
                  <div>
                    <span className="fw4 pr1">TTY</span>
                    <Link
                      className="black fw8 link"
                      to="tel:133677"
                      ariaLabel="Phone number 1 3. 3 6. 7 7"
                      rooted
                    >
                      133677
                    </Link>
                  </div>
                  <div>
                    <span className="fw4 pr1">SMS relay number</span>
                    <Link
                      className="black fw8 link"
                      to="tel:0423677767"
                      ariaLabel="Phone number 0 4 2 3. 6 7 7. 7 6 7"
                      rooted
                    >
                      0423 677 767
                    </Link>
                  </div>
                </div>
              </div>
              <PdfLink
                className="mt4"
                to={
                  explainingOurEnquiryProcessEasyEnglish.edges[0].node.publicURL
                }
                title="Easy English (PDF) - Talk to us about the NDIS supports you
              need"
              />
              <div className="dn db-l">
                <FeedbackAndComplaints />
              </div>
            </Box>
            <Box className="absolute-l top-0 right-1 center-m z-1 dn db-l w-100 w-third-l">
              <DisabilityForm formId="disability-contact-us-l" />
            </Box>
            <Box className="db dn-l w-100 w-third-l mt4">
              <DisabilityForm formId="disability" />
              <FeedbackAndComplaints />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-l layout-readable">
              <Box className="w-100 w-two-thirds-l">
                <Heading size={3} topMargin="none">
                  Information about the Disability Royal Commission
                </Heading>
                <p>
                  Life Without Barriers supports the Royal Commission into
                  Violence, Abuse, Neglect and Exploitation of people with
                  Disability (DRC). For any questions about Life Without
                  Barriers and the DRC, please call{" "}
                  <Link
                    to="tel:0240334500"
                    ariaLabel="Phone number 0 2. 4 0 3 3. 4 5 0 0"
                    className="black fw4"
                  >
                    02 4033 4500
                  </Link>{" "}
                  To report an incident, please use our{" "}
                  <Link
                    to="/contact-us/complaints-or-feedback/"
                    className="black fw4"
                    rooted
                  >
                    feedback and complaints form
                  </Link>
                  {". "}
                </p>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Our locations</Heading>
                <p>
                  Our disability services are available nation-wide. Each
                  location offers services and supports across multiple suburbs
                  and communities. Get in touch with us to discuss your
                  individual needs and how we can work together to support you.
                </p>
                <div className="pa4 ph5-ns mv4 mv5-l bg-lwb-theme-medium br3">
                  <LocationModal currentUrl={location.pathname} small={true} />
                </div>
                <ReadMoreLink
                  text="Find a location"
                  href="/contact-us/locations/"
                  rooted
                  className="mt3 color-lwb-theme-darker"
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/disability-contact-us.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    explainingOurEnquiryProcessEasyEnglish: allFile(
      filter: {
        name: {
          eq: "NDIS-LWB-309a-Enquiry-and-Engagement-Process-Fact Sheet-Easy-English"
        }
      }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

export default ContactUs
